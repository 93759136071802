import { TextField, Grid, Box, Typography, Button } from '@mui/material';
import _ from 'lodash';
import { SelectedNode } from '../superadmin.types';
import { useEffect, useState } from 'react';
import { useLambdas } from '../../../utils/lambdas';
import { useMutation } from "@apollo/client";
import { useAuth0 } from '@auth0/auth0-react';



interface NodeInfoFormProps  {
  nodeSelectedData: SelectedNode
  
}

const nodeFormFields = [
  { label: 'Name', name: 'name', type: 'text', required: false },
  { label: 'Description', name: 'description', type: 'text', required: false },
  { label: 'Priority', name: 'priority', type: 'number', required: false },
  { label: 'ExternalId', name: 'externalId', type: 'text', required: false },
];

const defaultNodeData: SelectedNode = {
  id: 0,
  name: '',
  priority: 0,
  description: '',
  expandable: false,
  expanded: false,
  color: '',
};

type FormData = {
  id: number;
  name: string;
  description: string;
  priority: number;
  externalId: string;
};

const NodeInfoForm = ({ nodeSelectedData } : NodeInfoFormProps) => {
  const { user, isLoading: isAccountLoading } = useAuth0();

  const [isDisabled, setIsDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false); 

  const nodeData = _.get(nodeSelectedData, 'data', defaultNodeData)

  const [formData, setFormData] = useState<FormData>({
    id: nodeData.id,
    name: nodeData.name,
    description: nodeData.description,
    priority: nodeData.priority,
    externalId: '',
  });

  // const [, executePostExisting] = useLambdas(
  //   'updateBuyer',
  //   user,
  //   {
  //     buyerId: Number(id),
  //     existingBuyerDetails: existingDetailsPayload,
  //   },
  //   true
  // );
  // const [, executePost] = useLambdas('updatePerson', user, {
  //   person: localCandidateDetails,
  // });
  
 

  // const [, executePost] = useLambdas(
  //   "updatePerson",
  //   user,
  //   { person: personValues }
  // )

  const [, executePost1] = useLambdas(


  // const [{ data, loading, error }] = useLambdas(
    "updateProcessStep",
    user,
    {
      processStep:formData
    }
    // checkoutSessionParams,
    // false
  )

  const handleSave = async () => {
    // setIsDisabled(true);
    if (isEditing) {
      console.log('Saving:', formData);
      return await executePost1()
      setIsDisabled(true); 
    } else {
      setIsDisabled(false);
    }
    setIsEditing(!isEditing);
  };


  const handleCancel = () => {
    setIsDisabled(false);
  };


  // useEffect(() => {
  //   if (nodeData.id !== 0 && nodeData.name.length > 0) {
  //     setIsDisabled(false);
  //   }
  // }, [nodeData]);

  useEffect(() => {
    if (nodeData.id !== 0 && nodeData.name.length > 0) {
      setIsDisabled(false);
    }
    setFormData({
      id: nodeData.id,
      name: nodeData.name,
      description: nodeData.description,
      priority: nodeData.priority,
      externalId: '',
    });
  }, [nodeData]);


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      console.log(name,value)
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
  

  return (
    <Box component="form" sx={{ padding: 2 }}>
      <Typography variant="h6">
        {nodeData?.name 
          ? ` ${nodeData?.name || ''}`
          : 'Lock and select an item'}
      </Typography>

      <Grid container spacing={2}>
        {nodeFormFields.map((field, index) => (
          <Grid item xs={12} key={index}>
            <TextField
              label={field.label}
              variant="outlined"
              type={field.type || 'text'}
              disabled={isDisabled}
              fullWidth
              required={field.required || false}
              name={field.name}
              value={formData[field.name as keyof FormData] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container justifyContent="flex-end" spacing={2} sx={{ marginTop: 2 }}>

        <Button onClick={handleSave}>{isEditing ? 'Save' : 'Edit'}</Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </Grid>
    </Box>
  );
};

export default NodeInfoForm;
